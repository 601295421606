import { BrowserRouter, Routes, Route } from "react-router-dom";
import Paths from "./Paths";
import AuthRoute from "./Guards/AuthRoute";
import PublicRoute from "./Guards/PublicRoute";
import CustomSuspense from "components/UI/RenderElements/CustomSuspense";
import React, { lazy, useMemo } from "react";

const DashboardLayout = lazy(() => import("components/layout/Dashboard"));
const Router = () => {
    const pageData = useMemo(() => Paths, []);

    return (
        <BrowserRouter>
            <Routes>
                {pageData.map((route) => (
                    <Route
                        key={route.path}
                        element={
                            route.layout === "dashboard" ? (
                                <DashboardLayout />
                            ) : null
                        }
                    >
                        <Route
                            path={route.path}
                            key={route.path}
                            element={
                                route.public ? (
                                    <PublicRoute>
                                        <CustomSuspense>
                                            {route.component}
                                        </CustomSuspense>
                                    </PublicRoute>
                                ) : (
                                    <AuthRoute>
                                        <CustomSuspense>
                                            {route.component}
                                        </CustomSuspense>
                                    </AuthRoute>
                                )
                            }
                        ></Route>

                        {route.children &&
                            route.children.map((child) => (
                                <Route
                                    path={child.path}
                                    key={child.path}
                                    element={
                                        <CustomSuspense>
                                            {child.component}
                                        </CustomSuspense>
                                    }
                                />
                            ))}
                    </Route>
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
