import "./index.css";
import AppRouter from "routes";
import { logout } from "store/auth";
import { UserService } from "services";
import { updateUser, updateCompany } from "store/user";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getLocalStorage } from "utils/helpers";
import ThemeCustomization from "themes";
import { useSelector } from "react-redux";

import LoadingPage from "components/UI/LoadingPage";

const userService = new UserService();

const App = () => {
    const { token } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);

                const token = getLocalStorage("token");

                if (token) {
                    const res = await userService.verifyToken(token);

                    if (!res?.data.user?.id) dispatch(logout());

                    const user_data = await userService.getUserById(
                        res?.data.user?.id
                    );

                    const { user } = user_data.data;
                    const payload = {
                        user,
                        token,
                    };
                    dispatch(updateUser(payload));
                }
            } catch (error) {
                dispatch(logout());
            }
            setLoading(false);
        }

        fetchData();
    }, [dispatch, token]);
    console.log(
        "%c  %c  %c  Created by HouseLabss %c  %c    ",
        "background: #000; color: #fff; font-size: 16px; padding: 5px;",
        "background: #000; color: #fff; font-size: 16px; padding: 5px;",
        "background: #de9802;; color: #000; font-size: 16px; padding: 5px;",
        "background: #de9802;; color: #fff; font-size: 16px; padding: 5px;",
        "background: #000; color: #fff; font-size: 16px; padding: 5px;"
    );
    return (
        <LoadingPage>
            <ThemeCustomization>
                <AppRouter />
            </ThemeCustomization>
        </LoadingPage>
    );
};

export default App;
