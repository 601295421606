// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme, color) => {
    switch (color) {
        case "secondary":
            return theme.palette.secondary;
        case "error":
            return theme.palette.error;
        case "warning":
            return theme.palette.warning;
        case "info":
            return theme.palette.info;
        case "success":
            return theme.palette.success;
        case "warningDark":
            return theme.palette.warningDark;
        case "errorDark":
            return theme.palette.errorDark;
        case "infoDark":
            return theme.palette.infoDark;
        case "successDark":
            return theme.palette.successDark;

        default:
            return theme.palette.primary;
    }
};

export default getColors;
