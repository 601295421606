import { enqueueSnackbar } from "notistack";

export const _openSnackbar = (message, variant) => {
  enqueueSnackbar(message, {
    variant: variant || "default",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    autoHideDuration: 3000,
  });
};

export const checkPermission = (permArr, role) => {
  return permArr.includes(role);
};

export const generateColorByName = (name) => {
  let hash = 0;
  for (let i = 0; i < name?.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

export function findClosestPrice(offerList) {
  if (!offerList || offerList.length === 0) {
    return null;
  }

  let closestIndex = 0;
  let closestDiff = Math.abs(new Date() - new Date(offerList[0].createdAt));

  for (let i = 1; i < offerList.length; i++) {
    const diff = Math.abs(new Date() - new Date(offerList[i].createdAt));
    if (diff < closestDiff) {
      closestDiff = diff;
      closestIndex = i;
    }
  }

  return offerList[closestIndex].price;
}
