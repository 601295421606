
import { lazy } from "react";

const Login = lazy(() => import("containers/Login"));
const Register = lazy(() => import("containers/Register"));
const Dashboard = lazy(() => import("containers/Dashboard"));
const Lands = lazy(() => import("containers/Lands"));
const OnSale = lazy(() => import("containers/OnSale"));
const Customers = lazy(() => import("containers/Customers"));
const Offers = lazy(() => import("containers/Offers"));
const OfferActions = lazy(() => import("containers/Offers/OfferActions"));
const AddOnSale = lazy(() => import("containers/OnSale/AddOnSale"));
const LandDetails = lazy(() => import("containers/Lands/LandDetails"));
const OnSaleDetails = lazy(() => import("containers/OnSale/OnSaleDetails"));

const Paths = [
    {
        id: 1,
        name: "Giriş Ekranı",
        path: "/login",
        component: <Login />,
        public: true,
        icon: "dashboard-outlined",
        layout: "",
        visible: false,
    },
    {
        id: 2,
        name: "Kayıt Ol",
        path: "/register",
        component: <Register />,
        public: true,
        icon: "dashboard-outlined",
        layout: "",
        visible: false,
    },

    {
        id: 3,
        name: "Dashboard",
        path: "/",
        component: <Dashboard />,
        public: false,
        icon: "dashboard-outlined",
        layout: "dashboard",
        visible: true,
    },
    {
        id: 7,
        name: "Teklifler",
        path: "/offers",
        component: <Offers />,
        public: false,
        icon: "schedule-outlined",
        layout: "dashboard",
        visible: true,
    },
    {
        id: 4,
        name: "Arsalar",
        path: "/lands",
        component: <Lands />,
        public: false,
        icon: "home-outlined",
        layout: "dashboard",
        visible: true,
    },
    {
        id: 41,
        name: "Arsa Detayı",
        path: "/lands/land-details/:id",
        component: <LandDetails />,
        public: false,
        icon: "home-outlined",
        layout: "dashboard",
        visible: false,

    },
    {
        id: 5,
        name: "İlanlar",
        path: "/on-sale",
        component: <OnSale />,
        public: false,
        icon: "shop-outlined",
        layout: "dashboard",
        visible: true,
    },
    {
        id: 51,
        name: "İlan Ekle",
        path: "/on-sale/add",
        component: <AddOnSale />,
        public: false,
        icon: "shop-outlined",
        layout: "dashboard",
        visible: false,
    },
    {
        id: 52,
        name: "İlan Düzenle",
        path: "/on-sale/edit/:id",
        component: <OnSale />,
        public: false,
        icon: "shop-outlined",
        layout: "dashboard",
        visible: false,
    },
    {
        id: 53,
        name: "İlan Detayı",
        path: "/on-sale/details/:id",
        component: <OnSaleDetails />,
        public: false,
        icon: "shop-outlined",
        layout: "dashboard",
        visible: false,
    },
    {
        id: 6,
        name: "Müşteriler",
        path: "/customers",
        component: <Customers />,
        public: false,
        icon: "team-outlined",
        layout: "dashboard",
        visible: true,
    },

    {
        id: 8,
        name: "Teklif Detayı",
        path: "/offers/offer-details/:id",
        component: <OfferActions />,
        public: false,
        icon: "dashboard-outlined",
        layout: "dashboard",
        visible: false,
    },

];

export default Paths;
