// third-party

// project import
import Chip from "./Chip";
import Typography from "./Typography";
// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return {
        ...Chip(theme),
        ...Typography(theme),
    };
}
