import PropTypes from "prop-types";

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Default = (colors) => {
    const { blue, red, gold, cyan, green, grey } = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16],
    };
    const contrastText = "#fff";

    return {
        primary: {
            lighter: "#B3CCF2", // Güncelle
            100: "#9CC4EE", // Güncelle
            200: "#85BAEA", // Güncelle
            light: "#6FAFE6", // Güncelle
            400: "#58A5E2", // Güncelle
            main: "#3C64AA", // Güncelle
            dark: "#2B4E87", // Güncelle
            700: "#1A3860", // Güncelle
            darker: "#082D4A", // Güncelle
            900: "#011E31",
            contrastText,
        },
        secondary: {
            lighter: greyColors[100],
            100: greyColors[100],
            200: greyColors[200],
            light: greyColors[300],
            400: greyColors[400],
            main: greyColors[500],
            600: greyColors[600],
            dark: greyColors[700],
            800: greyColors[800],
            darker: greyColors[900],
            A100: greyColors[0],
            A200: greyColors.A400,
            A300: greyColors.A700,
            contrastText: greyColors[0],
        },
        error: {
            lighter: red[0],
            light: red[2],
            main: red[4],
            dark: red[8],
            darker: red[9],
            contrastText,
        },
        errorDark: {
            lighter: red[1],
            light: red[5],
            main: red[6],
            dark: red[8],
            darker: red[9],
            contrastText,
        },

        warning: {
            lighter: gold[0],
            light: gold[3],
            main: gold[5],
            dark: gold[8],
            darker: gold[9],
            contrastText: greyColors[100],
        },
        warningDark: {
            lighter: gold[1],
            light: gold[4],
            main: gold[6],
            dark: gold[8],
            darker: gold[9],
            contrastText,
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[8],
            darker: cyan[9],
            contrastText,
        },
        infoDark: {
            lighter: cyan[1],
            light: cyan[4],
            main: cyan[6],
            dark: cyan[8],
            darker: cyan[9],
            contrastText,
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[8],
            darker: green[9],
            contrastText,
        },
        successDark: {
            lighter: green[1],
            light: green[4],
            main: green[6],
            dark: green[8],
            darker: green[9],
            contrastText,
        },
        black: {
            main: greyColors[900],
            light: greyColors[800],
            dark: greyColors[900],
            contrastText,
        },

        grey: greyColors,
    };
};

Default.propTypes = {
    colors: PropTypes.object,
};

export default Default;
