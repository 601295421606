import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkPermission } from "helpers/utils";
function AuthRoute({ children, permission }) {
    const { token } = useSelector(({ auth }) => auth);
    const info = useSelector(({ user }) => user);
    let location = useLocation();

    const navigator = (path = "/login") => (
        <Navigate to={path} state={{ from: location }} replace />
    );

    if (token) {
        if (!permission || checkPermission(permission, info?.user.role))
            return children;
        return navigator("/403");
    }
    return navigator();
}
export default AuthRoute;
