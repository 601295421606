import { CircularProgress, Box } from "@mui/material";
import { React, Suspense } from "react";

const FallbackCircularProgress = () => (
    <Box height="100vh">
        <Box
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size="large" />
        </Box>
    </Box>
);

const CustomSuspense = ({ fallback, children }) => {
    return (
        <Suspense fallback={fallback || <FallbackCircularProgress />}>
            {children}
        </Suspense>
    );
};

export default CustomSuspense;
