import PropTypes from "prop-types";

// project import
import Default from "./default";

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, presetColor, mode) => {
    // Can add theme selection logic here

    switch (presetColor) {
        case "default":
            return Default(colors, mode);
        default:
            return Default(colors, mode);
    }
};

Theme.propTypes = {
    colors: PropTypes.object,
    presetColor: PropTypes.any,
};

export default Theme;
