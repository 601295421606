import { CircularProgress, Box } from "@mui/material";

const LoadingPage = ({ children, loading }) => {
  return (
    <>
      {!loading ? (
        children
      ) : (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </>
  );
};

export default LoadingPage;
