import { Snackbar } from "@mui/material";
export const setLocalStorage = (name, value) =>
  localStorage.setItem(name, JSON.stringify(value));

export const getLocalStorage = (name) =>
  JSON.parse(localStorage.getItem(name)) || null;

export const removeLocalStorage = (name) => localStorage.removeItem(name);
export const setNotificationMessage = ({ type, ...rest }) => {
  Snackbar[type]({
    duration: 3,
    ...rest,
  });
};

export const checkPermission = (permArr, role) => {
  return permArr.includes(role);
};
