import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: {
    id: null,
    name: null,
    email: null,
    role: null,
  },
  token: null,
  refreshToken: null,
  isAuth: false,
  company: null,
};
const User = createSlice({
  name: "user",
  initialState,
  reducers: {
    userModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    setUserInitialState: () => initialState,
    companyModifiedData: (state, action) => {
      state.company = action.payload.data;
    },
  },
});

export const { userModifiedData, setUserInitialState, companyModifiedData } =
  User.actions;
export default User.reducer;

export const updateUser =
  ({ token, user }) =>
  (dispatch) => {
    token && dispatch(userModifiedData({ name: "token", data: token }));
    dispatch(userModifiedData({ name: "user", data: user }));
    dispatch(userModifiedData({ name: "isAuth", data: true }));
  };
export const updateCompany =
  ({ company }) =>
  (dispatch) => {
    dispatch(companyModifiedData({ name: "company", data: company }));
  };
